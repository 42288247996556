<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field v-if="UpdatedItem.logo"
                v-model="UpdatedItem.logo"
                color="custom-accent darken-4"
                :label="$t('logo')"
                :placeholder="$t('logo')"
                prepend-inner-icon="mdi-file"
                append-icon="mdi-delete"
                @click:append="deleteFile('logo')"
                readonly
                required
                :counter="maxTextfieldLength"
            ></v-text-field>
            <v-text-field v-if="UpdatedItem.small_logo"
                v-model="UpdatedItem.small_logo"
                color="custom-accent darken-4"
                :label="$t('smalllogo')"
                :placeholder="$t('smalllogo')"
                prepend-inner-icon="mdi-file"
                append-icon="mdi-delete"
                @click:append="deleteFile('small_logo')"
                readonly
                required
                :counter="maxTextfieldLength"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.alt"
                color="custom-accent darken-4"
                :label="$t('alttext')"
                :placeholder="$t('alttext')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.title"
                color="custom-accent darken-4"
                :label="$t('title')"
                :placeholder="$t('title')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.email"
                color="custom-accent darken-4"
                :label="$t('email')"
                :placeholder="$t('email')"
                required
                :counter="maxTextfieldLength"
                :rules="emailfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.phone"
                color="custom-accent darken-4"
                :label="$t('phone')"
                :placeholder="$t('phone')"
                required
                :counter="maxTextfieldLength"
                :rules="phonefieldRules"
            ></v-text-field>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
        }
    },
    components: { },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => emailRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        phonefieldRules(){ 
            const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('phoneValidError'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        UpdatedItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        deleteFile(context){
            this.$emit('deleteFile',context);
        },
        validate(){
            let isValid = this.$refs.form.validate();
            this.$emit('isValid','update',isValid);
        }
    }
})
</script>
<style scoped>
</style>
