<template>
  <v-container id="employer" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'employer-bg.jpg' + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{
              $t("joinourteam")
            }}</h1>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="ma-auto">
             <v-tabs
              v-model="tab"
              background-color="transparent"
              color="custom-accent"
              center-active
              show-arrows
              centered
            >
              <v-tab
                v-for="item in tabs"
                :key="item.tab"
              >
                {{ $t(item.tab) }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="10" lg="10" class="ma-auto">
            <v-tabs-items v-model="tab" class="transparent">
              <v-tab-item
                v-for="item in tabs"
                :key="item.tab"
              >
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-auto pa-0">
                    <v-card v-if="item.tab=='company'" class="elevation-0 transparent ma-2"><what-we-do :isDark="false"></what-we-do></v-card>
                    <v-card v-if="item.tab=='values'" class="elevation-0 transparent ma-2"><our-values :isDark="false"></our-values></v-card>
                    <v-card v-if="item.tab=='team'" class="elevation-0 transparent ma-2"><our-team></our-team></v-card>
                    <v-card v-if="item.tab=='careers'" class="elevation-0 transparent ma-2"><v-col cols="12" sm="10" md="8" class="ma-auto"><who-we-are></who-we-are></v-col></v-card>
                    <v-card v-if="item.tab=='locations'" class="elevation-0 transparent ma-2"><contact-us-info style="width:fit-content;"></contact-us-info></v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text pa-0 ma-0" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h6 text-uppercase">{{$t("interested")}}</div>
            <v-btn class="my-6" :title="$t('contact')" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("contact")}} {{$t("now")}}!<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import whatwedo from '../../components/Employer/WhatWeDo.vue';
import ourvalues from '../../components/Employer/OurValues.vue';
import ourteam from '../../components/Employer/OurTeam.vue';
import whoweare from '../../components/Employer/WhoWeAre.vue';
import contactusinfo from '../../components/Contact/ContactUsInfo.vue';
export default {
  name: "Employer",

  data() {
    return {
      tab: null,
      tabs: [
        { tab:'company' },
        { tab:'values' },
        { tab:'team' },
        { tab:'careers' },
        { tab:'locations' },
      ],

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "recruitments/employer/images/",
    };
  },
  components: {
    'what-we-do':whatwedo,
    'our-values':ourvalues,
    'our-team':ourteam,
    'who-we-are':whoweare,
    'contact-us-info':contactusinfo,
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
  },
};
</script>
<style scoped>
@import url("../../assets/styles/employer.css");
</style>