<template>
  <v-container id="ourteam" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="text-center">
        <span class="text-h4 text-uppercase">{{$t("ourteam")}}</span>
        <transition name="fade" mode="out-in" v-if="user_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="6"
              lg="4"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="users.length==0 && !user_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="users.length && !user_loading">
          <v-row class="ma-0 pa-0" justify="center" :key="itemsperpage * page">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('users.create')"
              cols="12">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateTeam()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
              </v-tooltip>
            </v-col>
            <v-col v-for="(item, i) in users.slice(
                  itemsperpage * (page - 1),
                  itemsperpage * page
                )" 
                :key="i"
                cols="12"
                sm="6"
                md="4"
                lg="4">
              <v-card class="ma-auto pa-4 elevation-0 transparent">
                <v-row class="pa-0 ma-0" style="position:absolute;right:-30px;top:0px;" v-if="user && isLoggedIn">
                  <v-col class="pa-0 ma-0" cols="12">
                    <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('users.update')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-auto" icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateTeam(item)" :title="$t('update')">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('update')}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12">
                    <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('users.delete')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-auto" icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteTeam(item)" :title="$t('delete')">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('delete')}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="12">
                    <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('users.update')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-auto" icon v-bind="attrs" v-on="on" @click.stop="OpenResetPassword(item)" :title="$t('resetpassword')">
                          <v-icon>mdi-lock-reset</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('resetpassword')}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-card-title class="ma-0 pa-2">
                    <v-avatar
                        class="ma-auto rounded-circle"
                        color="custom-accent"
                        size="88">
                        <span class="text-uppercase white--text text-h4">{{ item.firstname.charAt(0) }}{{ item.lastname.charAt(0)}}</span>
                    </v-avatar>
                </v-card-title>
                <v-card-title class="ma-0 pa-0 text-center">
                    <span class="ma-auto text-capitalize text--secondary">{{item.fullname}}</span>
                </v-card-title>
                <v-card-subtitle class="ma-0 pa-0 text-center">
                    <span class="ma-auto">{{item.email}}</span>
                </v-card-subtitle>
                <v-card-actions class="ma-0 pa-0">
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :href="`tel:`+item.phone" @click="copy(item.phone)" class="success--text ma-auto" :title="item.phone" dark v-bind="attrs" v-on="on" icon>
                              <v-icon>
                              mdi-phone
                              </v-icon>
                          </v-btn>
                        </template>
                        <span>{{item.phone}}</span>
                    </v-tooltip>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :href="`mailto:`+item.email" @click="copy(item.email)" class="secondary--text ma-auto" :title="item.email" dark v-bind="attrs" v-on="on" icon>
                              <v-icon>
                              mdi-mail
                              </v-icon>
                          </v-btn>
                        </template>
                        <span>{{item.email}}</span>
                    </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0">
          <v-col v-if="Math.ceil(users.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(users.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>

        <!-- create dialog -->
        <v-dialog v-model="teamcreateoverlay" width="800px">
          <v-card v-if="teamcreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <create-team @isValid="validate" :NewItem="activeteam"></create-team>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="teamcreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearTeam()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="user_loading" :disabled="!teamcreateformvalid" @click="CreateTeam()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="teamupdateoverlay" width="800px">
          <v-card v-if="teamupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <update-team @isValid="validate" :UpdatedItem="activeteam"></update-team>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="teamupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="user_loading" depressed :disabled="!teamupdateformvalid" @click="UpdateTeam()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="teamdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="teamdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="user_loading" depressed @click="DeleteTeam()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

        <!-- reset dialog -->
        <v-dialog v-model="passwordresetoverlay" width="800px">
          <v-card v-if="passwordresetoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("resetpassword")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <reset-password @isValid="validate" :UpdatedItem="activeteam"></reset-password>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="passwordresetoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('reset')" :loading="user_loading" depressed :disabled="!passwordresetformvalid" @click="ResetPassword()">
                <span>{{$t('reset')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- reset dialog -->

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import createteam from '../Team/CreateTeam.vue';
import updateteam from '../Team/UpdateTeam.vue';
import resetpassword from '../Team/ResetPassword.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "OurTeam",
  data() {
    return {
      page: 1,
      itemsperpage: 6,

      teamcreateoverlay: false,
      teamupdateoverlay: false,
      teamdeleteoverlay: false,
      passwordresetoverlay: false,
      teamcreateformvalid: false,
      teamupdateformvalid: false,
      passwordresetformvalid: false,
      activeteam: {},
    };
  },
  components: {
    'create-team':createteam,
    'update-team':updateteam,
    'reset-password':resetpassword,
  },
  created(){
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('user', {users:'getUsers'}),
    ...mapState("user", {user_loading: "loading"}),
  },
  watch: {},
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.teamcreateformvalid=isValid;
            break;

        case 'update':
            this.teamupdateformvalid=isValid;
            break;

        case 'reset':
            this.passwordresetformvalid=isValid;
            break;

        default:
            break;
      }
    },
    OpenCreateTeam() {
      this.teamcreateoverlay = true;
      this.activeteam = {};
    },
    OpenUpdateTeam(item) {
      this.teamupdateoverlay = true;
      this.activeteam = {...item};
    },
    OpenDeleteTeam(item) {
      this.teamdeleteoverlay = true;
      this.activeteam = {...item};
    },
    OpenResetPassword(item) {
      this.passwordresetoverlay = true;
      this.activeteam = {...item};
    },
    CreateTeam() {
      if(this.teamcreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('users.create')){
        this.activeteam.phone=this.activeteam.phone.replace(/\D/g,"");
        this.addUser(this.activeteam).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.teamcreateoverlay = false;
            this.activeteam = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateTeam() {
      if(this.teamupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('users.update')){        
        this.activeteam.phone=this.activeteam.phone.replace(/\D/g,"");
        this.updateUser(this.activeteam).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.teamupdateoverlay = false;               
            this.activeteam = {};
            this.UpdateTeams(response.data);
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteTeam() {
      if(this.user && this.user.permission.map(i=>i.name).includes('users.delete')){
        this.deleteUser(this.activeteam).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.teamdeleteoverlay = false;
            this.activeteam = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ResetPassword() {
      if(this.passwordresetformvalid && this.user && this.user.permission.map(i=>i.name).includes('users.update')){
        this.updateUser(this.activeteam).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.passwordresetoverlay = false;               
            this.activeteam = {};
            this.UpdateTeams(response.data);
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateTeams(payload) {
      const edited_item_index=this.users.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.users[edited_item_index] = payload;
    },
    ClearTeam() {
      this.activeteam = {};
    },

    ...mapActions("user", ["addUser","updateUser","deleteUser"]),
    
  },
};
</script>
 
