<template>
  <v-container id="contactusinfo" class="ma-auto" fluid>
    <v-skeleton-loader type="heading" min-width="500" v-if="company_loading"></v-skeleton-loader>
    <span class="title font-weight-medium custom-accent--text" v-else>{{company.title}}
      <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companies.update')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateCompany(company)" :title="$t('update')">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{$t('update')}}</span>
      </v-tooltip>
    </span>
    <span class="text-h6 text--secondary text-center text-md-left py-2 d-flex">{{$t("ouroffices")}}:</span>
    <div v-if="company_address_loading">
      <v-skeleton-loader type="image" min-width="500" height="120"></v-skeleton-loader>
    </div>
    <v-row class="ma-0 pa-0" v-else>
      <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyaddresses.create')" cols="12">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateCompanyAddress()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
          </template>
          <span>{{$t('create')}}</span>
        </v-tooltip>
      </v-col>
      <v-col class="font-weight-light pa-0 ma-0 pb-4" cols="12" v-for="item in company_addresses" :key="item.id">
        <v-input class="align-center" hide-details>
          <v-row class="pa-0 ma-0" style="position:absolute;right:-110px;top:-12px;" v-if="user && isLoggedIn">
            <v-col class="pa-0 ma-0" cols="12">
              <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyaddresses.update')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateCompanyAddress(item)" :title="$t('update')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('update')}}</span>
              </v-tooltip>
            </v-col>
            <v-col class="pa-0 ma-0" cols="12">
              <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyaddresses.delete')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteCompanyAddress(item)" :title="$t('delete')">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('delete')}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          {{item.address}}
          <v-tooltip right slot="prepend">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :href="`http://maps.google.com/?q=`+item.address" :title="$t('openinmap')" target="_blank" icon>
                <v-icon>
                  mdi-map-marker
                </v-icon>
              </v-btn>
            </template>
            <span>{{$t("openinmap")}}</span>
          </v-tooltip>
          <v-tooltip right slot="append">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ml-auto" :title="$t('copytoclipboard')" icon @click="copy(item.address)">
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </template>
            <span>{{$t("copytoclipboard")}}</span>
          </v-tooltip>
        </v-input>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="text-left">
        <span class="text-h6 text--secondary">{{$t("contactusat")}}:</span>
      </v-col>
    </v-row>
    <v-row v-if="company_loading" class="ma-0 pa-0">
      <v-skeleton-loader type="avatar" v-for="i in 2" :key="i" class="mr-1"></v-skeleton-loader>
    </v-row>
    <v-row class="ma-0 pa-0" v-else>
      <v-tooltip right class="mr-1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :href="`tel:`+company.phone" class="success--text mr-2" :title="company.phone" dark v-bind="attrs" v-on="on" icon>
            <v-icon>
              mdi-phone
            </v-icon>
          </v-btn>
        </template>
        <span>{{company.phone}}</span>
      </v-tooltip>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :href="`mailto:`+company.email" class="secondary--text mr-2" :title="company.email" dark v-bind="attrs" v-on="on" icon>
            <v-icon>
              mdi-mail
            </v-icon>
          </v-btn>
        </template>
        <span>{{company.email}}</span>
      </v-tooltip>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="text-left">
        <span class="text-h6 text--secondary">{{$t("followuson")}}:</span>
      </v-col>
    </v-row>
    <v-row v-if="company_social_loading" class="ma-0 pa-0">
      <v-skeleton-loader type="avatar" v-for="i in 3" :key="i" class="mr-1"></v-skeleton-loader>
    </v-row>
    <v-row class="ma-0 pa-0" v-else>
      <span class="my-auto" v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companysocials.create')">
        <v-row class="pa-0 ma-0 d-block">
          <v-col class="pa-0 ma-0 text-center">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="OpenCreateCompanySocial()" icon :title="$t('create')" class="ma-auto">
                    <v-icon>
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
              </template>
              <span>{{$t('create')}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </span>
      <span v-for="item in company_socials" :key="item.id" class="mr-1">
        <v-row class="pa-0 ma-0 d-block">
          <v-col class="pa-0 ma-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn :href="item.url" target="_blank" :title="item.name" v-bind="attrs" v-on="on" icon>
                    <v-icon :color="item.color">
                      {{item.icon}}
                    </v-icon>
                  </v-btn>
              </template>
              <span>{{item.name}}</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0 ma-0" v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companysocials.update')">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateCompanySocial(item)" :title="$t('update')">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$t('update')}}</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0 ma-0" v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companysocials.delete')">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteCompanySocial(item)" :title="$t('delete')">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{$t('delete')}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </span>
    </v-row>

      <!-- create address dialog -->
      <v-dialog v-model="companyaddresscreateoverlay" width="800px">
        <v-card v-if="companyaddresscreateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("additem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <create-company-address @isValid="validateAddress" :NewItem="activecompanyaddress"></create-company-address>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyaddresscreateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn dark depressed :title="$t('clear')" @click="ClearCompanyAddress()">{{$t('clear')}}</v-btn>
            <v-btn class="success" :title="$t('create')" depressed :loading="company_social_loading" :disabled="!companyaddresscreateformvalid" @click="CreateCompanyAddress()">
              <span>{{$t('create')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- create address dialog -->

      <!-- update address dialog -->
      <v-dialog v-model="companyaddressupdateoverlay" width="800px">
        <v-card v-if="companyaddressupdateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("updateitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <update-company-address @isValid="validateAddress" :UpdatedItem="activecompanyaddress"></update-company-address>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyaddressupdateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="company_social_loading" depressed :disabled="!companyaddressupdateformvalid" @click="UpdateCompanyAddress()">
              <span>{{$t('update')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- update address dialog -->

      <!-- delete address dialog -->
      <v-dialog v-model="companyaddressdeleteoverlay" width="500px">
        <v-card>
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("deleteitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyaddressdeleteoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="error" :title="$t('delete')" :loading="company_social_loading" depressed @click="DeleteCompanyAddress()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete address dialog -->

      <!-- create social dialog -->
      <v-dialog v-model="companysocialcreateoverlay" width="800px">
        <v-card v-if="companysocialcreateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("additem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <create-company-social @isValid="validateSocial" :NewItem="activecompanysocial"></create-company-social>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companysocialcreateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn dark depressed :title="$t('clear')" @click="ClearCompanySocial()">{{$t('clear')}}</v-btn>
            <v-btn class="success" :title="$t('create')" depressed :loading="company_social_loading" :disabled="!companysocialcreateformvalid" @click="CreateCompanySocial()">
              <span>{{$t('create')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- create social dialog -->

      <!-- update social dialog -->
      <v-dialog v-model="companysocialupdateoverlay" width="800px">
        <v-card v-if="companysocialupdateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("updateitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <update-company-social @isValid="validateSocial" :UpdatedItem="activecompanysocial"></update-company-social>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companysocialupdateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="company_social_loading" depressed :disabled="!companysocialupdateformvalid" @click="UpdateCompanySocial()">
              <span>{{$t('update')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- update social dialog -->

      <!-- delete social dialog -->
      <v-dialog v-model="companysocialdeleteoverlay" width="500px">
        <v-card>
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("deleteitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companysocialdeleteoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="error" :title="$t('delete')" :loading="company_social_loading" depressed @click="DeleteCompanySocial()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete social dialog -->

      <!-- update company dialog -->
      <v-dialog v-model="companyupdateoverlay" width="800px">
        <v-card v-if="companyupdateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("updateitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <file-input-drop v-if="!activecompany.logo" @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
            <file-input-drop v-if="!activecompany.small_logo" @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
            <update-company @isValid="validate" @deleteFile="OpenDeleteFile" :UpdatedItem="activecompany"></update-company>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyupdateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="company_loading" depressed :disabled="!companyupdateformvalid" @click="UpdateCompany()">
              <span>{{$t('update')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- update company dialog -->

      <!-- file delete company dialog -->
      <v-dialog v-model="filedeleteoverlay" width="500px">
        <v-card>
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("deleteitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="filedeleteoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="error" :title="$t('delete')" :loading="file_loading" depressed @click="DeleteFile()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- file delete company dialog -->

  </v-container>
</template>
<script>
import createcompanyaddress from '../CompanyAddress/CreateCompanyAddress.vue';
import updatecompanyaddress from '../CompanyAddress/UpdateCompanyAddress.vue';
import createcompanysocial from '../CompanySocial/CreateCompanySocial.vue';
import updatecompanysocial from '../CompanySocial/UpdateCompanySocial.vue';
import updatecompany from '../Company/UpdateCompany.vue';
import fileinputdrop from "../Admin/FileInputDrop.vue";
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "ContactUsInfo",
  data() {
    return {
      companyaddresscreateoverlay: false,
      companyaddressupdateoverlay: false,
      companyaddressdeleteoverlay: false,
      companyaddresscreateformvalid: false,
      companyaddressupdateformvalid: false,
      activecompanyaddress: {},

      companysocialcreateoverlay: false,
      companysocialupdateoverlay: false,
      companysocialdeleteoverlay: false,
      companysocialcreateformvalid: false,
      companysocialupdateformvalid: false,
      activecompanysocial: {},

      companyupdateoverlay: false,
      filedeleteoverlay: false,
      companyupdateformvalid: false,
      activecompany: {},

      allowedTypes: "image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "268435456",
      allowedLength: 20,
      multiple:false,
      logo_files: [],
      small_logo_files: [],

      default_path: "default_images/",
      file_delete_context: "",
    };
  },
  components: {
    'create-company-address':createcompanyaddress,
    'update-company-address':updatecompanyaddress,
    'create-company-social':createcompanysocial,
    'update-company-social':updatecompanysocial,
    'update-company':updatecompany,
    'file-input-drop':fileinputdrop,
  },
  async created() {
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('company', {companies:'getCompanies'}),
    ...mapGetters('company', {company:'getCompany'}),
    ...mapState("company", {company_loading: "loading"}),
    ...mapGetters('company_address', {company_addresses:'getCurrentCompanyAddresses'}),
    ...mapState("company_address", {company_address_loading: "loading"}),
    ...mapGetters('company_social', {company_socials:'getCurrentCompanySocials'}),
    ...mapState("company_social", {company_social_loading: "loading"}),
    ...mapState("file", {file_loading: "loading"}),
  },
  watch: {},
  methods: {
    copy(item) {
      navigator.clipboard.writeText(item).then(()=>{
        this.$root.$emit('callNotify', { icon:'mdi-content-copy',  content:this.$t('copiedtoclipboard'), title:this.$t('success'), type:'success'});
      })
    },
    validateAddress(context,isValid){
      switch (context) {
        case 'create':
            this.companyaddresscreateformvalid=isValid;
            break;

        case 'update':
            this.companyaddressupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    validateSocial(context,isValid){
      switch (context) {
        case 'create':
            this.companysocialcreateformvalid=isValid;
            break;

        case 'update':
            this.companysocialupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.companycreateformvalid=isValid;
            break;

        case 'update':
            this.companyupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    OpenCreateCompanyAddress() {
      this.companyaddresscreateoverlay = true;
      this.activecompanyaddress = {};
    },
    OpenUpdateCompanyAddress(item) {
      this.companyaddressupdateoverlay = true;
      this.activecompanyaddress = {...item};
    },
    OpenDeleteCompanyAddress(item) {
      this.companyaddressdeleteoverlay = true;
      this.activecompanyaddress = {...item};
    },
    CreateCompanyAddress() {
      if(this.companyaddresscreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companyaddresses.create')){
        this.activecompanyaddress.company = this.company.id;
        this.addCompanyAddress(this.activecompanyaddress).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.companyaddresscreateoverlay = false;
            this.activecompanyaddress = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateCompanyAddress() {
      if(this.companyaddressupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companyaddresses.update')){
        this.updateCompanyAddress(this.activecompanyaddress).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.companyaddressupdateoverlay = false;               
            this.activecompanyaddress = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteCompanyAddress() {
      if(this.user && this.user.permission.map(i=>i.name).includes('companyaddresses.delete')){
        this.deleteCompanyAddress(this.activecompanyaddress).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.companyaddressdeleteoverlay = false;
            this.activecompanyaddress = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearCompanyAddress() {
      this.activecompanyaddress = {};
    },

    OpenCreateCompanySocial() {
      this.companysocialcreateoverlay = true;
      this.activecompanysocial = {};
    },
    OpenUpdateCompanySocial(item) {
      this.companysocialupdateoverlay = true;
      this.activecompanysocial = {...item};
    },
    OpenDeleteCompanySocial(item) {
      this.companysocialdeleteoverlay = true;
      this.activecompanysocial = {...item};
    },
    CreateCompanySocial() {
      if(this.companysocialcreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companysocials.create')){
        this.activecompanysocial.company = this.company.id;
        this.addCompanySocial(this.activecompanysocial).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.companysocialcreateoverlay = false;
            this.activecompanysocial = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateCompanySocial() {
      if(this.companysocialupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companysocials.update')){
        this.updateCompanySocial(this.activecompanysocial).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.companysocialupdateoverlay = false;               
            this.activecompanysocial = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteCompanySocial() {
      if(this.user && this.user.permission.map(i=>i.name).includes('companysocials.delete')){
        this.deleteCompanySocial(this.activecompanysocial).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.companysocialdeleteoverlay = false;
            this.activecompanysocial = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearCompanySocial() {
      this.activecompanysocial = {};
    },

    OpenUpdateCompany(item) {
      this.companyupdateoverlay = true;
      this.activecompany = {...item};
    },
    OpenDeleteFile(context) {
      this.file_delete_context=context;
      this.filedeleteoverlay = true;
    },
    UpdateCompany() {
      if(this.companyupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companies.update')){
        if(this.activecompany.logo && !this.companies.find(x => x.id === this.activecompany.id).logo || this.activecompany.small_logo && !this.companies.find(x => x.id === this.activecompany.id).small_logo){
          if(this.activecompany.logo && !this.companies.find(x => x.id === this.activecompany.id).logo){
            const formData = new FormData();
            formData.append("path", this.default_path);
            for (let index = 0; index < this.logo_files.length; index++) {
              let file = this.logo_files[index];
              formData.append("data[" + index + "]", file);
            }
            this.addFile(formData).then((response)=>{
              if(!this.multiple)
                this.activecompany.logo = response.data[0].split(';')[0];
              this.updateCompany(this.activecompany).then(() => {
                  this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                  this.companyupdateoverlay = false;
                  this.activecompany = {};
                  //this.UpdateCompanies(response.data);
                },() => {
                  this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
              );
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            });
          }
          if(this.activecompany.small_logo && !this.companies.find(x => x.id === this.activecompany.id).small_logo){
            const formData = new FormData();
            formData.append("path", this.default_path);
            for (let index = 0; index < this.small_logo_files.length; index++) {
              let file = this.small_logo_files[index];
              formData.append("data[" + index + "]", file);
            }
            this.addFile(formData).then((response)=>{
              if(!this.multiple)
                this.activecompany.small_logo = response.data[0].split(';')[0];
              this.updateCompany(this.activecompany).then(() => {
                  this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                  this.companyupdateoverlay = false;
                  this.activecompany = {};
                  //this.UpdateCompanies(response.data);
                },() => {
                  this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
              );
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            });
          }
        }
        else{
          this.updateCompany(this.activecompany).then(() => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
              this.companyupdateoverlay = false;               
              this.activecompany = {};
              //this.UpdateClients(response.data);
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
      }
    },
    DeleteFile() {
      switch (this.file_delete_context) {
        case 'logo':
            if(this.activecompany.logo){
              let payload ={name:this.activecompany.logo, path:this.default_path};
              this.deleteFile(payload).then(()=>{
                  this.activecompany.logo='';
                  this.filedeleteoverlay = false;
                  this.updateCompany(this.activecompany).then(() => {
                      this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                      //this.UpdateClients(response.data);
                    },() => {
                      this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    }
                  );
                },() => {
                  this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
              );
            }
            break;

        case 'small_logo':
            if(this.activecompany.small_logo){
              let payload ={name:this.activecompany.small_logo, path:this.default_path};
              this.deleteFile(payload).then(()=>{
                  this.activecompany.small_logo='';
                  this.filedeleteoverlay = false;
                  this.updateCompany(this.activecompany).then(() => {
                      this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                      //this.UpdateClients(response.data);
                    },() => {
                      this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                    }
                  );
                },() => {
                  this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                }
              );
            }
            break;

        default:
            break;
      }
    },
    ClearCompany() {
      this.activecompany={logo:this.activecompany.logo,small_logo:this.activecompany.small_logo};
    },
    UploadFiles(files) {
      if(!this.activecompany.logo){
        this.logo_files=files;
        if(!this.multiple)
          this.$set(this.activecompany, 'logo', this.logo_files[0]?.name);
      }
      else if(!this.activecompany.small_logo){
        this.small_logo_files=files;
        if(!this.multiple)
          this.$set(this.activecompany, 'small_logo', this.small_logo_files[0]?.name);
      }
    },

    ...mapActions("company_address", ["addCompanyAddress","updateCompanyAddress","deleteCompanyAddress"]),
    ...mapActions("company_social", ["addCompanySocial","updateCompanySocial","deleteCompanySocial"]),
    ...mapActions("company", ["updateCompany"]),
    ...mapActions("file", ["addFile","deleteFile"]),
  },
};
</script>
 
