<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="UpdatedItem.name"
                color="custom-accent darken-4"
                :label="$t('address')"
                :placeholder="$t('address')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules">
            </v-text-field>
        </div>
        <v-menu v-model="icon_menu" min-width="auto" :close-on-content-click="false" open-on-click offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="UpdatedItem.icon"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="custom-accent darken-4"
                    :label="$t('icon')"
                    :placeholder="$t('icon')"
                    required
                    :counter="maxTextfieldLength"
                    :rules="textfieldRules"
                >
                    <template v-slot:append>
                        <v-icon :color="UpdatedItem.color">{{UpdatedItem.icon?UpdatedItem.icon:'mdi-border-none-variant'}}</v-icon>
                    </template>
                </v-text-field>
            </template>
            <icon-picker v-model="UpdatedItem.icon"></icon-picker>
        </v-menu>
        <v-menu v-model="color_menu" min-width="auto" :close-on-content-click="false" open-on-click offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="UpdatedItem.color"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="custom-accent darken-4"
                    :label="$t('color')"
                    :placeholder="$t('color')"
                    required
                    :counter="maxTextfieldLength"
                    :rules="textfieldRules"
                >
                    <template v-slot:append>
                        <v-icon :color="UpdatedItem.color">mdi-checkbox-blank-circle</v-icon>
                    </template>
                </v-text-field>
            </template>
            <color-picker v-model="UpdatedItem.color"></color-picker>
        </v-menu>
        <v-text-field
            v-model="UpdatedItem.url"
            color="custom-accent darken-4"
            :label="$t('url')"
            :placeholder="$t('url')"
            required
            :counter="maxTextfieldLength"
            :rules="textfieldRules">
        </v-text-field>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import iconpicker from "../Admin/IconPicker.vue";
import colorpicker from "../Admin/ColorPicker.vue";

export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
            icon_menu: false,
            color_menu: false,
        }
    },
    components: {
        'icon-picker':iconpicker,
        'color-picker':colorpicker,
    },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        UpdatedItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        validate(){
            let isValid = this.$refs.form.validate();
            this.$emit('isValid','update',isValid);
        }
    }
})
</script>
<style scoped>
</style>
