<template>
  <v-container id="whatwedo" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0" height="auto">
      <v-col cols="12" class="text-center text-md-left" data-aos="slide-right">
        <span class="text-h5 text-uppercase">{{$t("whatwedo")}}</span>
      </v-col>
      <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyvalues.create')"
        cols="12">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn block height="60" :dark="isDark" x-large v-bind="attrs" v-on="on" @click="OpenCreateCompanyValue()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
          </template>
          <span>{{$t('create')}}</span>
        </v-tooltip>
      </v-col>
      <v-row class="ma-0 pa-0" v-if="company_value_loading"> 
        <v-col cols="12" v-for="i in 3" :key="i" class="ma-auto" sm="8" md="4" data-aos="fade-down">
          <v-skeleton-loader
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader class="my-2"
            type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="sentences,button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-else> 
        <v-col cols="12" v-for="(item, i) in what_we_do" :key="item.id" class="ma-auto" sm="8" md="4" data-aos="fade-down">
          <v-card height="100%" class="values-cards" :class="{'transparent':isDark}" :dark="isDark" outlined>
            <v-row class="pa-0 ma-0" style="position:absolute;right:-30px;top:0px;" v-if="user && isLoggedIn">
              <v-col class="pa-0 ma-0" cols="12">
                <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyvalues.update')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :dark="isDark" v-bind="attrs" v-on="on" @click.stop="OpenUpdateCompanyValue(item)" :title="$t('update')">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('update')}}</span>
                </v-tooltip>
              </v-col>
              <v-col class="pa-0 ma-0" cols="12">
                <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('companyvalues.delete')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :dark="isDark" v-bind="attrs" v-on="on" @click.stop="OpenDeleteCompanyValue(item)" :title="$t('delete')">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('delete')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-card-title>
                <v-icon class="text-h1 ma-auto" x-large>
                  {{item.icon}}
                </v-icon>
            </v-card-title>
            <v-card-title>
              <span class="ma-auto v-html-line-clamp-1 font-weight-light text-uppercase">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
            </v-card-title>
            <v-card-title class="font-weight-light subtitle-2">
              <span :class="{ 'v-html-line-clamp-2': i != expandableValue}" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span>
            </v-card-title>
            <v-card-actions>
              <v-btn :title="$t('viewmore')" class="ma-auto" v-if="i != expandableValue" text @click.stop="ExpandValue(i)">{{$t("viewmore")}}</v-btn>
              <v-btn :title="$t('viewless')" class="ma-auto" v-else text @click.stop="expandableValue=null">{{$t("viewless")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- create dialog -->
      <v-dialog v-model="companyvaluecreateoverlay" width="800px">
        <v-card v-if="companyvaluecreateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("additem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <create-company_value @isValid="validate" :NewItem="activecompanyvalue"></create-company_value>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyvaluecreateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn dark depressed :title="$t('clear')" @click="ClearCompanyValue()">{{$t('clear')}}</v-btn>
            <v-btn class="success" :title="$t('create')" depressed :loading="company_value_loading" :disabled="!companyvaluecreateformvalid" @click="CreateCompanyValue()">
              <span>{{$t('create')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- create dialog -->

      <!-- update dialog -->
      <v-dialog v-model="companyvalueupdateoverlay" width="800px">
        <v-card v-if="companyvalueupdateoverlay">
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("updateitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">
            <update-company_value @isValid="validate" :UpdatedItem="activecompanyvalue"></update-company_value>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyvalueupdateoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="success" :title="$t('update')" :loading="company_value_loading" depressed :disabled="!companyvalueupdateformvalid" @click="UpdateCompanyValue()">
              <span>{{$t('update')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- update dialog -->

      <!-- delete dialog -->
      <v-dialog v-model="companyvaluedeleteoverlay" width="500px">
        <v-card>
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("deleteitem")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed :title="$t('close')" @click="companyvaluedeleteoverlay = false">{{$t('close')}}</v-btn>
            <v-btn class="error" :title="$t('delete')" :loading="company_value_loading" depressed @click="DeleteCompanyValue()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete dialog -->

    </v-row>
  </v-container>
</template>
<script>
import createcompanyvalue from '../CompanyValue/CreateCompanyValue.vue';
import updatecompanyvalue from '../CompanyValue/UpdateCompanyValue.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "WhatWeDo",
  props: ["isDark"],
  data() {
    return {
      companyvaluecreateoverlay: false,
      companyvalueupdateoverlay: false,
      companyvaluedeleteoverlay: false,
      companyvaluecreateformvalid: false,
      companyvalueupdateformvalid: false,
      expandableValue: null,
      activecompanyvalue: {},
    };
  },
  components: {
    'create-company_value':createcompanyvalue,
    'update-company_value':updatecompanyvalue,
  },
  async created(){
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('company', {company:'getCompany'}),
    ...mapState("company", {company_loading: "loading"}),
    ...mapGetters('company_value', {what_we_do:'getWhatWeDo'}),
    ...mapState("company_value", {company_value_loading: "loading"}),
  },
  watch: {},
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.companyvaluecreateformvalid=isValid;
            break;

        case 'update':
            this.companyvalueupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    ExpandValue(i) {
      this.expandableValue = i;
    },
    OpenCreateCompanyValue() {
      this.companyvaluecreateoverlay = true;
      this.activecompanyvalue = {};
    },
    OpenUpdateCompanyValue(item) {
      this.companyvalueupdateoverlay = true;
      this.activecompanyvalue = {...item};
    },
    OpenDeleteCompanyValue(item) {
      this.companyvaluedeleteoverlay = true;
      this.activecompanyvalue = {...item};
    },
    CreateCompanyValue() {
      if(this.companyvaluecreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companyvalues.create')){
        this.activecompanyvalue.type = 'whatwedo';
        this.activecompanyvalue.company = this.company.id;
        this.addCompanyValue(this.activecompanyvalue).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.companyvaluecreateoverlay = false;
            this.activecompanyvalue = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateCompanyValue() {
      if(this.companyvalueupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('companyvalues.update')){
        this.updateCompanyValue(this.activecompanyvalue).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.companyvalueupdateoverlay = false;
            this.activecompanyvalue = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteCompanyValue() {
      if(this.user && this.user.permission.map(i=>i.name).includes('companyvalues.delete')){
        this.deleteCompanyValue(this.activecompanyvalue).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.companyvaluedeleteoverlay = false;
            this.activecompanyvalue = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearCompanyValue() {
      this.activecompanyvalue = {};
    },

    ...mapActions("company_value", ["addCompanyValue","updateCompanyValue","deleteCompanyValue"]),
  },
};
</script>
 
