<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="NewItem.name"
                color="custom-accent darken-4"
                :label="$t('name')"
                :placeholder="$t('name')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules">
            </v-text-field>
            <v-menu v-model="icon_menu" min-width="auto" :close-on-content-click="false" open-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="NewItem.icon"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        color="custom-accent darken-4"
                        :label="$t('icon')"
                        :placeholder="$t('icon')"
                        required
                        :counter="maxTextfieldLength"
                        :rules="textfieldRules"
                    >
                        <template v-slot:append>
                            <v-icon :color="NewItem.color">{{NewItem.icon?NewItem.icon:'mdi-border-none-variant'}}</v-icon>
                        </template>
                    </v-text-field>
                </template>
                <icon-picker v-model="NewItem.icon"></icon-picker>
            </v-menu>
            <v-menu v-model="color_menu" min-width="auto" :close-on-content-click="false" open-on-click offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="NewItem.color"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        color="custom-accent darken-4"
                        :label="$t('color')"
                        :placeholder="$t('color')"
                        required
                        :counter="maxTextfieldLength"
                        :rules="textfieldRules"
                    >
                        <template v-slot:append>
                            <v-icon :color="NewItem.color">mdi-checkbox-blank-circle</v-icon>
                        </template>
                    </v-text-field>
                </template>
                <color-picker v-model="NewItem.color"></color-picker>
            </v-menu>
            <v-text-field
                v-model="NewItem.url"
                color="custom-accent darken-4"
                :label="$t('url')"
                :placeholder="$t('url')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules">
            </v-text-field>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import iconpicker from "../Admin/IconPicker.vue";
import colorpicker from "../Admin/ColorPicker.vue";

export default({
    
    props: ['NewItem'],
    data() {
        return {
            valid: false,
            icon_menu: false,
            color_menu: false,
        }
    },
    components: {
        'icon-picker':iconpicker,
        'color-picker':colorpicker,
    },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        NewItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        validate(){
            let isValid = this.$refs.form.validate();
            this.$emit('isValid','create',isValid);
        }
    }
})
</script>
<style scoped>
</style>
