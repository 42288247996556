<template>
    <v-color-picker
      v-model="selected_color"
      show-swatches
      swatches-max-height="200px"
    ></v-color-picker>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      selected_color: '',
    };
  },
  created(){
    if(this.value)
      this.selected_color=this.value;
  },
  mounted() {
  },
  computed: {
  },
  watch:{
    selected_color(val){
      this.$emit('input', val)
    }
  },
  methods: {
  }
};
</script>