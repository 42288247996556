<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="UpdatedItem.username"
                color="custom-accent darken-4"
                prepend-icon="mdi-account-box"
                :label="$t('username')"
                :placeholder="$t('username')"
                required
                readonly
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.email"
                color="custom-accent darken-4"
                prepend-icon="mdi-email"
                :label="$t('email')"
                :placeholder="$t('email')"
                required
                readonly
                :counter="maxTextfieldLength"
                :rules="emailfieldRules"
            ></v-text-field>
           <v-text-field
                v-model="NewPassword"
                :append-icon="shownew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="shownew ? 'text' : 'password'"
                prepend-icon="mdi-lock-check"
                style="white-space: pre-line;"
                @click:append="shownew = !shownew"
                color="custom-accent darken-4"
                :label="$t('newpassword')" 
                :placeholder="$t('newpassword')"
                autocomplete="new-password"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
           <v-text-field
                v-model="ConfirmNewPassword"
                :append-icon="showconfirmnew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showconfirmnew ? 'text' : 'password'"
                prepend-icon="mdi-lock-check-outline"
                style="white-space: pre-line;"
                @click:append="showconfirmnew = !showconfirmnew"
                color="custom-accent darken-4"
                :label="$t('confirmnewpassword')"
                :placeholder="$t('confirmnewpassword')"
                autocomplete="new-password"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
            shownew: false,
            showconfirmnew: false,
            matcherror: false,
            NewPassword:'',
            ConfirmNewPassword:'',
        }
    },
    components: {},
    computed: {
         maxTextfieldLength () {
            return 200;
        },
        maxPasswordfieldLength () {
            return 16;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => emailRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        passwordfieldRules(){ 
            const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => passwordRegex.test(v) || this.$t('passwordValidError'),
                () => !this.matcherror || this.$t('passwordMatchError'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        UpdatedItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
        NewPassword() {
            this.validate();
        },
        ConfirmNewPassword() {
            this.validate();
        },
    },
    methods: {
        validate(){
            if (this.NewPassword != this.ConfirmNewPassword) 
                this.matcherror = true;
            else
                this.matcherror = false;
            
            let isValid = this.$refs.form.validate() && this.NewPassword && this.ConfirmNewPassword && !this.matcherror;
            if(isValid)
                this.UpdatedItem.password=this.NewPassword;
            this.$emit('isValid','reset',isValid);
        }
    }
})
</script>
<style scoped>
</style>
