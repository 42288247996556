<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
             <v-text-field
                v-model="NewItem.firstname"
                color="custom-accent darken-4"
                prepend-icon="mdi-card-account-details"
                :label="$t('firstname')"
                :placeholder="$t('firstname')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.lastname"
                color="custom-accent darken-4"
                prepend-icon="mdi-card-account-details-outline"
                :label="$t('lastname')"
                :placeholder="$t('lastname')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.username"
                color="custom-accent darken-4"
                prepend-icon="mdi-account-box"
                :label="$t('username')"
                :placeholder="$t('username')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.email"
                color="custom-accent darken-4"
                prepend-icon="mdi-email"
                :label="$t('email')"
                :placeholder="$t('email')"
                required
                :counter="maxTextfieldLength"
                :rules="emailfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.phone"
                color="custom-accent darken-4"
                prepend-icon="mdi-cellphone"
                :label="$t('phone')"
                :placeholder="$t('phone')"
                required
                :counter="maxTextfieldLength"
                :rules="phonefieldRules"
            ></v-text-field>
            <v-text-field
                v-model="NewItem.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                style="white-space: pre-line;"
                @click:append="show = !show"
                color="custom-accent darken-4"
                :label="$t('password')"
                :placeholder="$t('password')"
                required
                :counter="maxPasswordfieldLength"
                :rules="passwordfieldRules"
            ></v-text-field>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default({
    
    props: ['NewItem'],
    data() {
        return {
            valid: false,
            show: false,
        }
    },
    components: {},
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        maxPasswordfieldLength () {
            return 16;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => emailRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        passwordfieldRules(){ 
            const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => passwordRegex.test(v) || this.$t('passwordValidError'),
            ]
        },
        phonefieldRules(){ 
            const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('phoneValidError'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        NewItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        validate(){
            let isValid = this.$refs.form.validate();
            this.$emit('isValid','create',isValid);
        }
    }
})
</script>
<style scoped>
</style>
